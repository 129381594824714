import { autobind } from 'frontools/decorators/Autobind';
declare var dataLayer: any;
    
declare var Swiper: any

export default class Sliders {
    private _sliders            : NodeList;
    private _firstInteraction   : boolean           = true;
    private _obj                : any               = {
                                                        //autoHeight: true,
                                                        loop: false,
                                                        autoplay: 3000,
                                                        pagination: '.swiper-pagination',
                                                        autoplayDisableOnInteraction: false,
                                                        paginationClickable: true,
                                                        parallax: true,
                                                        setWrapperSize: true, 
                                                        speed: 1000,
                                                        onSlideChangeStart: () => {
                                                            if(this._firstInteraction) {
                                                                dataLayer.push({ eventCategory: 'accueil', eventAction: 'carrousel', eventLabel: 'carrousel', event: 'genericGAEvent' });
                                                                this._firstInteraction = false;
                                                            }
                                                        }
                                                    };
    private _swipers        : Array<Swiper>         = [];
    
    constructor(private _selector: string = '.swiper-container', obj?: Object) {
        if (obj !== undefined) this._obj = obj;

        this._sliders = document.querySelectorAll(_selector);

        if (this._sliders.length > 0) {
            for(let i = 0; i <= this._sliders.length; i++) {
                this._init((this._sliders[i] as HTMLElement), this._obj);
            }
        }
    }

    private _init(el: HTMLElement, obj) {
        let swiper = new Swiper(el, obj);   
        this._swipers.push(swiper);
    }

    @autobind
    public destroy() {      
       for(let i; i < this._swipers.length; i++) {
           if(this._swipers[i].destroy(true, true)) {
                this._swipers[i].destroy(true, true);
           }
       }
    }
}
