/**
 * Breakpoint
 * (c) lg2fabrique 2017
 *
 */

import AbstractDispatcher from '../abstract/AbstractDispatcher';
import DocumentUtils from '../utils/DocumentUtils';

export default class Breakpoint extends AbstractDispatcher {

    private static _instance: Breakpoint;
    private _debug: boolean = false;

    public static MOBILES: string = 'mobiles';
    public static MOBILE: string = 'mobile';
    public static TABLETP: string = 'tabletp';
    public static TABLET: string = 'tablet';
    public static DESKTOP: string = 'desktop';
    public static LARGE: string = 'large';
    public static HD: string = 'hd';

    private _value: string = null;
    private _lastValue: string = null;
    private _activeIndex: number = 0;
    private _currentGroups: string[] = [];
    private _lastGroups: string[] = [];
    private _groups: Object = {};
    private _breakpoints: string[] = [Breakpoint.MOBILES, Breakpoint.MOBILE, Breakpoint.TABLETP, Breakpoint.TABLET, Breakpoint.DESKTOP, Breakpoint.LARGE, Breakpoint.HD]; // Boilerplate defaults query
    private _debounceDelay: number = 25;

    /**
     * constructor
     * @param {Object} Breakpoint values
     */
    constructor(values:any = {}) {
        super();

        for (var i in values) {
            if (i === 'breakpoints') this._breakpoints = values[i];
            else if(i === 'groups') this._groups = values[i];
            else if(i === 'debug') this._debug = values[i];
            else this[i] = values[i];
        }

        //singleton validation
        if(Breakpoint._instance) throw Error('Breakpoint Error: Use Breakpoint.getInstance() instead of new.');
        Breakpoint._instance = this;


        // Create breakpoint constant and change value if users has specified some
        this.setBreakpoints(this._breakpoints);

        // Init resize
        let resizeDebounce = DocumentUtils.debounce(function() {
            this.onResize();
        }.bind(this), this._debounceDelay, true);
        window.addEventListener('resize', resizeDebounce);

        if (this._value === '') {
            throw('Breakpoint error: Warning no breakpoint set with CSS inside body:after');
        }
    };


    /**
     * function trigger - Trigger event(s) according to active breakpoint(s)
     * @param {boolean} forceGroupTrigger - Force group triggers even if they are currently active, use this with caution
     *
     */
    public static trigger(forceGroupTrigger: boolean = false): void {
        let breakpoint = Breakpoint.getInstance();
        breakpoint.dispatch({type: breakpoint._value});
        
        // Group query trigger
        if (breakpoint._groups !== null) {
            breakpoint._currentGroups = breakpoint.getMatchingGroups(breakpoint._value);

            // We make sure that only a match will trigger an event
            if (breakpoint._currentGroups.length > 0) {

                for (var i = 0, l = breakpoint._currentGroups.length; i < l; i++) {
                    if (breakpoint._lastGroups.indexOf(breakpoint._currentGroups[i]) === -1 || forceGroupTrigger) {
                        breakpoint.dispatch({type: breakpoint._currentGroups[i]});
                    }
                }

            }
            breakpoint._lastGroups = breakpoint._currentGroups;
        }
    };


    /**
     * function dispatch - AbstractDispatcher.dispatch override to log what was dispatched
     *
     * @param {object} event - Event object
     *
     */
    public dispatch(event: any): void {
        super.dispatch(event);
        if (this._debug) console.info('BREAKPOINT: ' + event.type);
    };


    /**
     * function on - Add event listener Breakpoint instance
     *
     * @param {String} type - Event's type
     * @param {Function} listener - callback
     *
     */
    public static on(type: string, listener: Function): void {
        Breakpoint.getInstance().addListener(type, listener);
    };


     /**
     * function off - Remove event listener from Breakpoint instance
     *
     *
     * @param {String} type - Event's type
     * @param {Function} listener - callback used when on was called
     *
     */
    public static off(type: string, listener: Function): void {
        Breakpoint.getInstance().removeListener(type, listener);
    };


    /**
     * Test if active breakpoint is lower than a specific breakpoint
     *
     * @param {String} breakpoint - Breakpoint to test
     * @return {Boolean} Test value
     */
    public static isLowerThan(breakpoint: string): boolean {
        return Breakpoint.getInstance()._activeIndex < Breakpoint.getInstance()._breakpoints.indexOf(breakpoint);
    };


    /**
     * Test if active breakpoint is equal to a specific breakpoint
     *
     * @param {String} breakpoint - Breakpoint to test
     * @return {Boolean} Test value
     *
     */
    public static isEqualTo(breakpoint: string): boolean {
        return Breakpoint.getInstance()._activeIndex === Breakpoint.getInstance()._breakpoints.indexOf(breakpoint);
    };


    /**
     * function isHigherThan - Test if active breakpoint is higher than a specific breakpoint
     *
     * @param {String} breakpoint - Breakpoint to test
     * @return {Boolean} Test value
     *
     */
    public static isHigherThan(breakpoint: string): boolean {
        return Breakpoint.getInstance()._activeIndex > Breakpoint.getInstance()._breakpoints.indexOf(breakpoint);
    };

    /**
     * function isInGroup - Test if active value is in specified group
     *
     * @param {String} groupName - Group to test
     * @return {Boolean} Presence of not of the current value in the specified group
     *
     */
    public isInGroup(groupName: string): boolean {
        let groups = this.getMatchingGroups(this._value);

        for (let i = 0, l = groups.length; i < l; i++) {
            if (groups[i] === groupName) return true;
        }

        return false;
    };
     

    /**
     * function getValue - Get active value
     * @return {String}
     *
     */
    public static getValue(): string {
        return Breakpoint.getInstance()._value;
    };


    /**
     * function getLastValue - Get last active value
     * @return {String}
     *
     */
    public static getLastValue(): string {
        return Breakpoint.getInstance()._lastValue;
    };


    /**
     * function getBreakpoints - Get breakpoints default value
     * @return {String}
    */
    public static getBreakpoints(): string[] {
        return Breakpoint.getInstance()._breakpoints;
    };


    /**
     * function setBreakpoints - Change breakpoints default value and create breakpoint constant
     * @param {Array}
    */
    private setBreakpoints(opts: string[]) {
        if (opts instanceof Array) {
            for (var i = 0, len = opts.length; i < len; i++) {
                this._breakpoints[i] = opts[i];
            }
        }
    };


    /**
     * function getMatchingGroups - Get groups that contains queries that contains the current browser query
     *
     * @param {string} value - Media query string
     * @return {array} Array containing matching group(s)
     */
    private getMatchingGroups(value: string): string[] {
        let groups = [];
        for (var key in this._groups) {
            if (this._groups.hasOwnProperty(key)) {
                for (let i = 0, len = this._groups[key].length; i < len; i++) {
                    if (this._groups[key][i] === value) {
                        groups.push(key);
                    }
                }
           }
       }

        // If we have no matches, we return an empty array
        return groups;
    };


    /**
     * Resize handler
     */
    private onResize() {
        // Save last breakpoint
        this._lastValue = this._value;

        // Refresh value from CSS
        this._value = window.getComputedStyle(document.querySelector('body'), ':after').getPropertyValue('content').replace(/\"/g, '').toLowerCase();

        this._activeIndex = this._breakpoints.indexOf(this._value);
        // Do not call trigger() onload, otherwise all listeners will be saved after the event has been dispatched
        if (this._lastValue !== this._value && this._lastValue !== null) {
            Breakpoint.trigger();
        }
    };


    /**
     * get singleton instance
     * @returns {Breakpoint}  instance's Breakpoint
     */
    public static getInstance(): Breakpoint {
        if (typeof Breakpoint._instance === 'undefined') {
            throw Error('Breakpoint error: Breakpoint has to be instantiated first via new Breakpoint();');
        }
        return Breakpoint._instance;
    };

}
