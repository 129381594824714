import Request from 'frontools/data/Request';
import { RequestConfig, RequestEvent, RequestMethod } from 'frontools/data/Request';
import { autobind} from 'frontools/decorators/Autobind';
import Context from 'frontools/core/Context';
import Breakpoint from 'frontools/core/Breakpoint';
import { OverlayCategory } from '../modules/OverlayCategory';

export class ProjectsList {
    private _links                  : NodeList;
    private _projectsList           : HTMLElement;
    private _categoriesOverlay      : HTMLElement;

    private _activeLink             : HTMLElement;
    
    constructor() { 
        this._projectsList = document.querySelector('.m_projects_list') as HTMLElement;
        this._categoriesOverlay = document.querySelector('.nav_select_box') as HTMLElement;
        this._parseLinks(); // Select ajax links

        this._activeLink = document.querySelector('[data-js="link"].active') as HTMLElement;

        // Initial push state
        var stateObj = new StateObj(window.location.href);
        history.replaceState(stateObj, window.location.href, window.location.href);
        if ('scrollRestoration' in history) history.scrollRestoration = 'manual'; // Save scroll position
        window.onpopstate = this._onPopState.bind(this);
        
        // On window resize
        this._onResesize();

        /*Breakpoint.on('group_large', () => {
            this._categoriesOverlay.removeEventListener('click', this._overlayCategory);
        });*/


     /* Breakpoint.on('group_small', () => {
            this._categoriesOverlay.addEventListener('click', this._overlayCategory);
        }); */
        
        if (window.innerWidth < 1280) {
            this._categoriesOverlay.addEventListener('click', this._overlayCategory);
        }

        if (window.innerWidth > 1280) {
            this._categoriesOverlay.removeEventListener('click', this._overlayCategory);
        }
    }

    private _overlayCategory() {
        let overlay = new OverlayCategory("list");
    }

    /*
    * Click on category link or pagination
    */
    private _onClickLink(e) {
        e.preventDefault();
        var target = e.target;

        // Prevent same category loading
        if(target == this._activeLink) return false;

        // Add to history 
        var stateObj = new StateObj(target.href);
        history.pushState(stateObj, target.href, target.href);
        
        // Load projects
        this._requestProjects(stateObj);
    };

    /*
     * _onLoad - When the request is successful
     * @param {e} - Custom event
     */
    @autobind
    private _onLoad(e) {
        TweenMax.staggerTo(this._projectsList.querySelectorAll('.project'), 0.3, { alpha: 0, ease: Expo.easeOut }, 0.2);

        // save response in new div and update projects
        var div = document.createElement('div');
        div.innerHTML = e.response; 
        
        // Setting activing link
        let activeLink = div.querySelector('[data-js="link"].active') as HTMLElement;
        let newLink = document.querySelector('[data-js="link"][href="'+activeLink.getAttribute('href')+'"]') as HTMLElement;
        this._activeLink.classList.remove('active');
        this._activeLink.blur();
        this._activeLink = newLink;
        this._activeLink.classList.add('active');

        var newEl = div.querySelector('.m_projects_list');
        this._projectsList.innerHTML = newEl.innerHTML;
        document.title = div.querySelector('title').innerText; // update page title 

        TweenMax.staggerFrom(this._projectsList.querySelectorAll('.project'), 0.3, { alpha: 0, ease: Expo.easeOut }, 0.2);
        
        ScrollUtils.scrollTo({
            target: document.querySelector('.projects_categories'),
            offset: document.querySelector('.header_main').clientHeight,
            speed: 1,
            easing: Expo.easeOut
        });
    };
    
    /*
    * Request projects on pop state
    */
    private _onPopState(e) {
        if(e.state) this._requestProjects(e.state);
    };

    /*
    * Select all ajax links and bind click event 
    */
    private _parseLinks() {
        this._links = document.querySelectorAll('[data-js="link"]');
        for(let i = 0; i < this._links.length; i++) {
            this._links[i].addEventListener('click', this._onClickLink.bind(this));
        }  
    };

    /* 
    * Request (frontools)
    */
    private _requestProjects(state: StateObj) {
        // Load new projects
        let url = state.url as string;
        let config = new RequestConfig();
        config.url = url;

        // Création (et envoi de la requête)
        let request = new Request(config);

        // Événements à écouter
        request.addListener(RequestEvent.LOAD, this._onLoad);
    };


    private _onResesize() {
        window.addEventListener('resize', () => {
            if (window.innerWidth < 1280) {
                this._categoriesOverlay.addEventListener('click', this._overlayCategory);
            }
    
            if (window.innerWidth > 1280) {
                this._categoriesOverlay.removeEventListener('click', this._overlayCategory);
            }
          });
    }
}

class StateObj {
    constructor(public url:string) {}
}