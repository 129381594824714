/**
 * UrlUtils
 * (c) lg2fabrique 2016
 *
 */


import Context from '../core/Context';

export default class UrlUtils {

    /**
     * Removes a specific param from url and cleans it afterward
     * @param {String} Url that might contains the param
     * @param {String} Param to be removed if present
     * @return {String}
     *    UrlUtils.removeQuery('http://www.lg2.com?foo=bar&bar=foo#123', 'foo') => http://www.lg2.com?bar=foo#123';
     */
    public static removeQuery(url:string, param:string): string {
        var pattern = new RegExp('([?&]' + param.replace(/([\\\/\[\]{}().*+?|^$])/g, '\\$1') + '=)[^&#]*' );
        return pattern.test(url) ? url.replace(pattern, '').replace(/^([^?]+)&/, '$1?') : url;
    };


    /**
     * Updates (add/modify) a specific param from url and cleans it afterward
     * @param {String} Uri that has to be modified
     * @param {String} Key to look for/to modify
     * @param {String} New value
     * @return {String}
     *    UrlUtils.updateQuery('http://www.lg2.com?foo=bar&bar=foo#123', 'foo', 'banana') => http://www.lg2.com?foo=banana&bar=foo#123';
     */
    public static updateQuery(uri:string, key:string, value:any): string {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            return uri + separator + key + "=" + value;
        }
    };


    /**
     * Add query to a url
     * @param {String} Url that has to be modified
     * @param {any} Object key/value
     * @return {String}
     *    UrlUtils.query('http://www.lg2.com?foo=bar&bar=foo#123', {test:'toto'}) => http://www.lg2.com?foo=banana&bar=foo&test=toto#123';
     */
    public static query(url:string, params:any): string{
        var no = 0;
        if(url.indexOf('?') == -1) url += '?';
        else no = 1;

        for(var i in params){
            url = UrlUtils.addQuery(url, i, params[i]);
        }

        return url;
    }


    /**
     * Add query to a url
     * @param {String} Url that has to be modified
     * @param {String} Param name
     * @param {String} Param value
     * @return {String}
     *    UrlUtils.addQuery('http://www.lg2.com?foo=bar&bar=foo#123', 'test', 'toto') => http://www.lg2.com?foo=banana&bar=foo&test=toto#123';
     */
    public static addQuery(url:string, param:string, value:any) {
        var a = document.createElement('a'), regex = /(?:\?|&amp;|&)+([^=]+)(?:=([^&]*))*/g;
        var match, str = []; a.href = url; param = encodeURIComponent(param);
        while (match = regex.exec(a.search))
            if (param != match[1]) str.push(match[1]+(match[2]?"="+match[2]:""));
        str.push(param+(value?"="+ encodeURIComponent(value):""));
        a.search = str.join("&");
        return a.href;
    }


    /**
     * URL parser
     * @param {string} [url]  Url string to be parsed
     * @returns {Object}  Url parts as an object
     */
    public static parse(url:string = document.location.toString()):IUri {
        var key = ['source','protocol','authority','userInfo','user','password','host','port','relative','path','directory','file','query', 'hash'];
        var query = {name:'params', parser: /(?:^|&)([^&=]*)=?([^&]*)/g};
        var parser = /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/;
        var match = parser.exec(url);

        var uri = <any>{};
        var index = key.length;
        while (index--) uri[key[index]] = match[index] || '';

        uri[query.name] = {};
        uri[key[12]].replace(query.parser, function (params, id, value) {if (id) uri[query.name][id] = value;});

        uri.url = url;

        return uri;
    }


    /**
     * Detecte if an url is external or internal to the domain
     * @param {String} Uri that has to be tested
     * @return {String}
     *    UrlUtils.isExternalURL('http://www.lg2.com') => true;
     */
    public static isExternalURL(url:string):boolean {

        if (url.substring(0, 4) === 'http' || url.substring(0, 2) === '//') {
            if(UrlUtils.parse().host != UrlUtils.parse(url).host){
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    /**
     * Detecte if an url is a IP
     * @param {String} url that has to be tested
     * @return {String}
     *    UrlUtils.isIPaddress('127.0.0.1') => true;
     */
    public static isIPaddress(url:string):boolean {
        var patt = new RegExp(/^(((1?[1-9]?|10|2[0-4])\d|25[0-5])($|\.(?!$))){4}$/g);
        return patt.test(UrlUtils.parse(url).host);
    };

}


//URI interface
interface IUri{
    source: string;
    protocol: string;
    authority: string;
    userInfo: string;
    user: string;
    password: string;
    host: string;
    port: string;
    relative: string;
    path: string;
    directory: string;
    file: string;
    query: string;
    hash: string;
    params: string;
    url:string;
}