import Sliders from '../modules/Sliders';
import { autobind } from 'frontools/decorators/Autobind';
import { debounce } from 'frontools/decorators/Debounce';
import Breakpoint from 'frontools/core/Breakpoint';
declare var dataLayer: any; 
export class About {
    private _sliders         : Sliders;
    private _firstInteraction   : boolean           = true;
    private _obj                : any               = {
                                                        // autoHeight: true,
                                                        autoplay: 3000,
                                                        pagination: '.swiper-pagination',
                                                        autoplayDisableOnInteraction: false,
                                                        paginationClickable: true,
                                                        parallax: true,
                                                        speed: 1000,
                                                        loop: false,
                                                        setWrapperSize: true, 
                                                        onSlideChangeStart: () => {
                                                            if(this._firstInteraction) {
                                                                dataLayer.push({ eventCategory: 'à propos', eventAction: 'carrousel', eventLabel: 'carrousel', event: 'genericGAEvent' });
                                                                this._firstInteraction = false;
                                                            }
                                                        }
                                                    };

    constructor() {
        Breakpoint.on('group_desktop', () => {
            this._removeSliders();
            this._sliders = new Sliders('.swiper-container', this._obj);
        });

        Breakpoint.on('group_small', () => {
            this._removeSliders();
            this._mobileSlider();
        });
    }

    private _mobileSlider() {
        let obj = {
            autoplay: 3000,
            autoplayDisableOnInteraction: false,    
            loop: false,
            pagination: '.swiper-pagination',
            paginationClickable: true,
            parallax: true,
            setWrapperSize: true,
            speed: 1000,
            onClick: () => {
                if(this._firstInteraction) {
                    dataLayer.push({ eventCategory: 'à propos', eventAction: 'carrousel', eventLabel: 'carrousel', event: 'genericGAEvent' });
                    this._firstInteraction = false;
                }
            }
        };
        this._sliders = new Sliders('.swiper-container-mobile', obj);
    }

    private _removeSliders() {
        if (this._sliders) {
            this._sliders.destroy();
            this._sliders = null;
        }
    }
}