import Overlay from '../modules/Overlay';
import { OverlayOptions } from '../modules/Overlay';

export default class MobileNav extends Overlay {
    protected _contentId        : string;
    
    constructor(id) {
        let _options = new OverlayOptions(); // Get options and override if needed
        _options.templateId = id;
        super(_options);

        this._contentId = _options.templateId; // get youtube template

        document.documentElement.classList.add('menu_active');
        this._open();
    }
    
    public _close() {
        super._close();
        document.documentElement.classList.remove('menu_active');
    }
    
    protected _generateTemplate(id) {
        super._generateTemplate(id);
    }
    
    protected _open() {
        super._open();
        TweenMax.set(document.documentElement.querySelector('.wrapper'), { clearProps: 'y' }); // to keep header on top
    }


     
}