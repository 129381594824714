import ScrollUtils from 'frontools/utils/ScrollUtils';

export class SmoothScroll {
    private _scrollToBtn              : HTMLElement;
    private _el              : HTMLElement;

    
    constructor(el) {
        this._el = el;
        this._el.addEventListener('click', this._scrollDown.bind(this));

        console.log('SmoothScrollll');
        
    }

    private _scrollDown(e) {
        ScrollUtils.scrollTo({
            target: document.querySelector('.scroll-anchor'),
            offset: document.querySelector('.header_main').clientHeight,
            speed: 2,
            easing: Expo.easeOut,
        });
    }   
}