/**
 * KeyCode
 * (c) lg2fabrique 2016
 *
 */

export default class KeyCode {

    public static ESC = 27;
    public static TAB = 9;
    public static ENTER = 13;
    public static SHIFT = 16;
    public static CTRL = 17;
    public static ALT = 18;
    public static LEFT = 37;
    public static UP = 38;
    public static RIGHT = 39;
    public static DOWN = 40;

}