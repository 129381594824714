// import { UIElements } from '../modules/UIElements';
import Overlay from '../modules/Overlay';
import { OverlayOptions } from '../modules/Overlay';
import OverlayYouTube from '../modules/OverlayYouTube';
import Youtube from 'frontools/medias/video/Youtube';
import {YoutubeObject} from 'frontools/medias/video/Youtube';
import VideoPlayer from 'frontools/medias/video/VideoPlayer';
import Sliders from '../modules/Sliders';
import { Header } from '../shared/Header';
import Breakpoint from 'frontools/core/Breakpoint';

export class Home {   
    private _scrollToBtn              : HTMLElement;
    private _sliders                  : Sliders;

    private _elVideos           : NodeListOf<HTMLElement>;
    private _videos             : Array<VideoPlayer>            = [];

    private _videoActive        : boolean                       = false;

    constructor() {
        this._videoSlide();
        this._sliders = new Sliders();

        Breakpoint.on('group_desktop', () => {
            if(!this._videoActive) {
                for (let i = 0, l = this._elVideos.length; i < l; i++) {
                    let video = this._elVideos[i];
                    this._initVideos(this._videos[i], video);    
                }
            }
        });

        Breakpoint.on('group_mobile', () => {
            if(this._videoActive) {
                for (let i = 0, l = this._elVideos.length; i < l; i++) {
                    let video = this._elVideos[i];
                    this._removeVideo(video);
                    this._videoActive = false;
                }
            }
        });
    }    

    private _videoSlide() {
        this._elVideos = document.querySelectorAll('.swiper-slide .video') as NodeListOf<HTMLElement>;
        
        for (let i = 0, l = this._elVideos.length; i < l; i++) {
            let video = this._elVideos[i];

            if (Breakpoint.isLowerThan(Breakpoint.TABLET)) {
                this._replaceImgFallback(video as HTMLElement);
            } else {
                this._initVideos(this._videos[i], video);    
            }
        }
    }

    private _replaceImgFallback(el: HTMLElement) {
        el.classList.remove('video');
        el.classList.add('slide__img-container')   
        let image = document.createElement('img') as HTMLImageElement;
        image.src = el.getAttribute('data-img-fallback');
        image.classList.add('object-fit');
        image.setAttribute('data-object-fit', 'cover');
        el.appendChild(image);
    }

    private _removeVideo(el: HTMLElement) {
        el.removeChild(document.querySelector('video'));
        this._replaceImgFallback(el);
    }

    private _initVideos(videoPlayer, videoEl) {
        this._videoActive = true;
        
        // C.P - slide_img-container is use for img only
        if (videoEl.classList.contains('slide__img-container')) {
            videoEl.classList.remove('slide__img-container')
        }

        videoPlayer = new VideoPlayer({
            target: videoEl,                     
            volume: 0,
            fullsize: true,    
            forceratio: true,
            position: 'relative',
            video:{
                attributes:{ loop: true, autoplay: true, playsinline: true, preload: "auto", muted: true}
            },
            callbacks:{ 
                oncanplay: (e) => {
                    e.target.play();
                },
                onerror: (e) => {
                    // alert('error')
                }
            }
        });
    }
}
