import Overlay from './Overlay';
import { OverlayOptions } from './Overlay';
declare var dataLayer: any;

export class OverlayCategory {
    private _overlay            : Overlay;
    private _overlayOptions     : OverlayOptions;

    constructor(id: string) {
        this._overlayOptions = new OverlayOptions();
        this._overlayOptions.templateId = id;
        this._overlayOptions.autoOpen = true;
        this._init();
    }

    private _init() {
        this._overlay = new Overlay(this._overlayOptions);
        this._reParseGTM();
    }

    private _close() {
        // console.log('close');
    }
    private _open() {
        // console.log('open');
    }


    /**
     * initializer of GTM data-tracking items
     */
    private _reParseGTM(){
        let trackingCtas: NodeList = document.querySelectorAll('.overlay_list [data-tracking]') ;
        for (let i = 0; i < trackingCtas.length; i++) {
            trackingCtas[i].addEventListener('click', (e) => {  
                let data = (trackingCtas[i] as HTMLElement).getAttribute('data-tracking').split('|');
                dataLayer.push({eventCategory: data[0], eventAction: data[1], eventLabel: data[2], event: 'genericGAEvent'});
            });
        }
    }
}