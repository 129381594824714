import ScrollUtils from 'frontools/utils/ScrollUtils';
import Breakpoint from 'frontools/core/Breakpoint';
import MobileNav from './MobileNav';
import AbstractDispatcher from 'frontools/abstract/AbstractDispatcher';
import { autobind } from 'frontools/decorators/Autobind';
declare var dataLayer: any;

export class Header extends AbstractDispatcher {
    public static HEADERHEIGHT  : number = document.querySelector('.header_main').clientHeight;

    private _headerEl           : HTMLElement;
    private _burgerBtn          : HTMLElement;
    private _mobileNav          : HTMLElement;

    private _nav                : MobileNav;
    private _burgerTl           : TimelineMax;

    constructor() {
        super();

        this._headerEl = document.querySelector('.header_main') as HTMLElement;
        this._mobileNav = this._headerEl.querySelector('.nav_main_inner') as HTMLElement;
        this._burgerBtn = this._headerEl.querySelector('.mobile_nav_button') as HTMLElement;

        this._init();
    }

    private _init() {
        Breakpoint.on('group_large', () => {
            ScrollUtils.registerElement(this._headerEl, {
                offset: -this._headerEl.clientHeight,
                after: el => { this._addScrollState(); },
                before: el => { this._removeScrollState(); }
            });
            this._burgerBtn.removeEventListener('click', this._handleMobileMenu);
            this.destroyOverlay();
        });

        Breakpoint.on('group_small', () => {
            this._headerEl.classList.remove('unscrolled', 'scrolled');
            ScrollUtils.removeRegistredElement(this._headerEl);
            this._burgerBtn.addEventListener('click', this._handleMobileMenu);
        });
    }

    private _addScrollState() { 
        this._headerEl.classList.remove('unscrolled');
        this._headerEl.classList.add('scrolled'); 
    }

    private _activateBurger() {
        this._burgerBtn.classList.add('active');
    }
    
    private _animateBurger() {
        this._burgerTl = new TimelineMax();
        this._burgerTl.to(this._burgerBtn.querySelector('.center'), 0.5, { rotation: '-45deg', autoAlpha: 0, ease: Back.easeIn })
                      .to(this._burgerBtn.querySelector('.top'), 0.5, { y: 10, rotation: '45deg', ease: Back.easeIn }, '-=0.5')
                      .to(this._burgerBtn.querySelector('.bottom'), 0.5, { y: -10, rotation: '-45deg', ease: Back.easeIn, onComplete: this._activateBurger.bind(this) }, '-=0.5')
                
    }
    
    private _clearBurger() {
        this._burgerTl.reverse();
        TweenMax.delayedCall((this._burgerTl.time()), () => {
            this._burgerBtn.classList.remove('active');
            TweenMax.set(this._burgerBtn.querySelectorAll('span'), { clearProps: 'all' });
        });
    }

    private _removeScrollState() { 
        this._headerEl.classList.add('unscrolled');
        this._headerEl.classList.remove('scrolled');
    }

    /* 
    * Create overlay Mobile menu 
    * or close it if exists
    */
    @autobind
    private _handleMobileMenu() {
        if (!document.querySelector('.overlay_nav_wrp')) {
            this._nav = new MobileNav('nav_wrp');
            this._nav.addListener(MobileNav.BEFORE_OPEN, this._animateBurger.bind(this));
            this._nav.addListener(MobileNav.BEFORE_CLOSE, this._clearBurger.bind(this));
            dataLayer.push({ uri: '/menu', pageType: 'menu', event: 'virtualPageview' });
            
        } else if (this._nav && document.querySelector('.overlay_nav_wrp')) {
            this._nav._close();
            this._nav = null;
        }
    }

    public destroyOverlay() {
        if (this._nav) this._nav._close();
    } 
}