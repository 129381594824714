/**
 * Context
 * (c) lg2fabrique 2016
 */

import UrlUtils from '../utils/UrlUtils';

export default class Context {

    protected static _instance      : Context;

    private _environment            : string                = 'production';
    private _language               : string                = 'fr';
    private _region                 : string                = 'ca';
    private _culture                : string                = 'fr-ca';

    /**
     * constructor
     * @param {Object} context values
     */
    constructor(values:any) {

        //singleton validation
        if (Context._instance) throw new Error('Error: Use Context.getInstance() instead of new.');
        Context._instance = this;

        //recording value to dictonnary
        for (var i in values) {
            if(i === 'environment') this._environment = values[i];
            else if(i === 'lang') this.parseLangue(values[i]);
            else if(i === 'langue') this.parseLangue(values[i]);
            else if(i === 'language') this.parseLangue(values[i]);
            else this[i] = values[i];
        }

    }

    private parseLangue(lang:string){
        if(lang.length == 2) {
            this._language = lang;
        } else {
            var culture;
            if(lang.indexOf('-') != -1){
                culture = lang.split('-');
                this._language = culture[0];
                this._region = culture[1];
            } else if(lang.indexOf('_') != -1){
                culture = lang.split('_');
                this._language = culture[0];
                this._region = culture[1];
            } else{
                this._language = lang;
                this._region = lang;
            }

            this._language = this._language.toLowerCase();
            this._region = this._region.toLowerCase();
            this._culture = this._language + '-' + this._region;
        }
    }

    /**
     * Get mobile simple
     * @return {boolean}
     */
    public isMobile():boolean {
        if (typeof window.orientation !== 'undefined' && this.isTouch()) return true;
        else return false;
    }

    /**
     * Get touch support
     * @return {boolean}
     */
    public isTouch():boolean {
        return !!('ontouchstart' in window);
    }

    /**
     * Get local environment
     * @return {boolean}
     */
    public isLocal():boolean {
        return this.environment === 'local' ? true : false;
    };

    /**
     * Get staging environment
     * @return {boolean}
     */
    public isStaging():boolean {
        return this.environment === 'staging' ? true : false;
    };

    /**
     * Get production environment
     * @return {boolean}
     */
    public isProduction():boolean {
        return this.environment === 'production' ? true : false;
    };

    /**
     * Get IE browser
     * @return {boolean}
     */
    public isIE() {
        return  navigator.userAgent.indexOf('MSIE') !== -1 ||
            navigator.appVersion.indexOf('Trident/') > 0 ||
            navigator.appVersion.indexOf('Edge/') > 0
    };

    /**
     * Get IE11 and lower browser
     * @return {boolean}
     */
    public isIE11andLower() {
        return  (window.navigator.userAgent.indexOf('MSIE ') > 0) || 
                (!!(window as any).MSInputMethodContext && 
                !!(document as any).documentMode); 
    };

    /**
     * Get Chrome browser
     * @return {boolean}
     */
    public isChrome():boolean {
        var ua = navigator.userAgent;
        if ( /Chrome/i.test( ua ) ) return true;
        else return false;
    };

    /**
     * Get Chrome browser in iOS
     * @return {boolean}
     */
    public isChromeIOS():boolean {
        var ua = navigator.userAgent;
        if ( /CriOS/i.test( ua ) ) return true;
        else return false;
    };

    /**
     * Get Firefox browser
     * @return {boolean}
     */
    public isFirefox():boolean {
        var ua = navigator.userAgent;
        if (/Firefox/i.test( ua )) return true;
        else return false;
    };

    /**
     * Get Safari Mobile browser
     * @return {boolean}
     */
    public isSafariMobile():boolean {
        var ua = navigator.userAgent;
        if (/Mobile(\/.*)? Safari/i.test( ua )) return true;
        else return false;
    };

    /**
     * Get Safari browser
     * @return {boolean}
     */
    public isSafari():boolean {
        var ua = navigator.userAgent;
        if(!this.isSafariMobile()){
            if (/Safari/i.test( ua )){
                if (/Chrome/i.test( ua )) return false;
                else return true;
            }else return false;
        }else return false;
    };

    /**
     * Get Android OS
     * @return {boolean}
     */
    public isAndroid():boolean {
        var ua = navigator.userAgent;
        if ( /Android/i.test( ua ) ) return true;
        else return false;
    };


    /**
     * Get iOS OS
     * @return {boolean}
     */
    public isIOS():boolean {
        var ua = navigator.userAgent;
        if (/iP[ao]d|iPhone/i.test( ua )) return true;
        else return false;
    };

    /**
     * Get iPad
     * @return {boolean}
     */
    public isIPad():boolean {
        var ua = navigator.userAgent;
        if (/iP[ao]d/i.test( ua )) return true;
        else return false;
    };

    /**
     * Get iPhone
     * @return {Bboolean}
     */
    public isIPhone():boolean {
        var ua = navigator.userAgent;
        if (/iPhone/i.test( ua )) return true;
        else return false;
    };

    /**
     * Get URL
     * @param {string} [url]  Url to get info from if not the current url
     * @return {Object}  host, domain, query, hash, ...
     */
    public getURL(url:string = undefined):any {
        var uri = UrlUtils.parse(url);
        return {
            uri: uri,
            url: uri.protocol + '://' + uri.host + (uri.port ? ':' + uri.port:'') + uri.path,
            host: uri.protocol + '://' + uri.host+ (uri.port ? ':' + uri.port:''),
            query: uri.params
        };
    };

    /**
     * Get Host
     * @return {string} host url
     */
    public get host():string {
        var uri = UrlUtils.parse();
        return uri.protocol + '://' + uri.host + (uri.port ? ':' + uri.port:'');
    };

    /**
     * Get viewport properties
     * @return {Object} width, height, htmlWidth, htmlHeight
     */
    public get viewport():any {
        return {
            width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
            htmlWidth: Math.max(document.documentElement.clientWidth, document.body.scrollWidth, document.documentElement.scrollWidth, document.body.offsetWidth, document.documentElement.offsetWidth),
            htmlHeight: Math.max(document.documentElement.clientHeight, document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight)
        };
    };

    /**
     * Get language
     * @return {string} fr / en
     */
    public get language():string {
        return this._language;
    };

    /**
     * Get region
     * @return {string} ca / us ...
     */
    public get region():string {
        return this._region;
    };

    /**
     * Get culture
     * @return {string} fr-ca / fr-fr ...
     */
    public get culture():string {
        return this._culture;
    };

    /**
     * Get environment
     * @return {string} local / staging / production
     */
    public get environment():string {
        return this._environment;
    };

    /**
     * Get context values
     * @param {string} Value name
     * @param {any} Default Value if request is undefine
     * @return {any} Value
     */
    public getValue(name:string, defaultValue?: any):any {
        if(this[name]) return this[name];
        else{
            if(defaultValue !== undefined) return defaultValue;
            else return false;
        }
    };

    /**
     * Get cookies value
     * @param {String} Value name
     * @param {any} Default value if no cookie for the name
     * @return {any} Value
     */
    public getCookie(name:string, defaultValue?: any):any {
        if (document.cookie.length > 0) {
            var start = document.cookie.indexOf(name + '=');
            var end;
            if (start != -1) {
                start = start + name.length + 1;
                end = document.cookie.indexOf(';', start);
                if (end == -1)  end = document.cookie.length;
                return encodeURI(document.cookie.substring(start, end));
            }
        }
        return defaultValue != undefined ? defaultValue : undefined;
    };

    /**
     * Set cookie value
     * @param {string} Value name
     * @param {any} Value data
     * @param {number} Expire after X days
     */
    public setCookie(name:string, value: any, days?:number) {
        var host = (window as any).location.host.replace(/\:[0-9]{1,4}/g, ''); //sans port
        
        var domain = host.split('.');
        if(!UrlUtils.isIPaddress(host) && domain.length > 2) {
            domain = '.' + domain.slice(-(domain.length - 1)).join('.');
        } else domain = '';
        
        var expires = '';
        if (days) {
            var date:Date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = date.toUTCString();
        }
        document.cookie = name + '=' + value + '; expires=' + expires + '; path=/; domain='+ domain +';';
    };

    /**
     * get singleton instance
     * @returns {Context}  instance's Context
     */
    public static getInstance():Context {
        return Context._instance;
    }

}
