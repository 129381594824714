import OverlayYouTube from './OverlayYouTube';
import AbstractDispatcher from 'frontools/abstract/AbstractDispatcher';
import LazyLoadManager, { LazyLoadManagerOptions } from 'frontools/medias/image/LazyLoadManager';
import AnimManager from 'frontools/animations/AnimManager';
import Breakpoint from 'frontools/core/Breakpoint';
import ObjectFitPolyfill from './ObjectFitPolyfill';

export class UIElements extends AbstractDispatcher {
    private _overlaysYt                 : NodeList;
    private _lazyLoadManager            : LazyLoadManager;
    private _lazyLoadOptions            : LazyLoadManagerOptions;
    private _animManager                : AnimManager;
    private _redLine                : HTMLElement;


    constructor() {
        super(); 


        this._lazyLoadOptions = new LazyLoadManagerOptions();
        this._lazyLoadOptions.offset = -180;
        this._lazyLoadManager = new LazyLoadManager(this._lazyLoadOptions); 
    
        this._overlaysYt = document.querySelectorAll('[data-overlay][data-youtube]');
        
        if (this._overlaysYt.length > 0) {
            for(let i = 0; i < this._overlaysYt.length; i++) {
                this._overlaysYt[i].addEventListener('click', this._initYtOverlays);
            }
        }

        this._redLine = (document.querySelector('[data-js="red-line"]') as HTMLElement);
        
        // inview animation manager
        if (Breakpoint.isHigherThan('tabletp')) {
            if(document.querySelector('[data-js="red-line"]')) this._lineAnimation();
            this._initAnimManager();
        }

        new ObjectFitPolyfill(); 
    }
 
    private _initYtOverlays(e) {
        e.preventDefault();

        const ytId = e.currentTarget.getAttribute('data-youtube');
        let overlay = new OverlayYouTube(ytId);
    }

    private _initAnimManager() {
        this._animManager = new AnimManager();
    }

    private _lineAnimation() {
        TweenMax.from(this._redLine, 3, { y: '-50%', scaleY: 0 /*y: -this._redLine.clientHeight - window.innerHeight*/ , ease: Power2.easeOut });
    }
}