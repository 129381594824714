import AbstractDispatcher from 'frontools/abstract/AbstractDispatcher';
import { autobind } from 'frontools/decorators/Autobind';
import Template from 'frontools/data/Template';

export default class Overlay extends AbstractDispatcher {
    public static BEFORE_OPEN       : string        = 'before_open';
    public static OPEN              : string        = 'open';
    public static BEFORE_CLOSE      : string        = 'before_close';
    public static CLOSE             : string        = 'close';

    private _options                : OverlayOptions;
    protected _contentId            : string;  
    
    private _el                     : HTMLElement;   
    private _closeBtn               : NodeList;
    private _isOpen                 : boolean       = false;

    private _scrollTop              : number        = 0;

    constructor(_options: OverlayOptions = new OverlayOptions()) {
        super();

        this._options = _options; 
        this._contentId = this._options.templateId;

        this._init();
    }

    private _init() {
        this._generateTemplate(this._contentId);
    }

    /*
    *   Accessiblity exit _onEsc key
    */
    @autobind
    private _onEsc(e: KeyboardEvent) {
        if (e.keyCode == 27) {
            this._close();
        }
    };
   
    /*
    *   Accessiblity focus tab on overlay
    */
    @autobind
    private _onKeyDown(e: KeyboardEvent) {
        if (e.target === document.activeElement && (e.which == 9)) {
            e.preventDefault();
            (this._el.querySelector('[tabindex="-1"]') as HTMLElement).focus();
        }
    };

    /*
    *   Accessiblity exit _onWrapperclick
    */
    @autobind
    private _onWrapperclick(e) {
        if (e.target === e.currentTarget) {
            this._close();
        }
    };

    /*
    * _generateTemplate
    * @params: Template id 
    * Default youtube template
    * Super._generateTemplate(id) to change template
    */
    protected _generateTemplate(_templateId: string) {
        const overlay = new Template((document.getElementById('overlay') as HTMLElement), {
            content: new Template((document.getElementById(_templateId) as HTMLElement), {}).htmlAsString
        }).htmlAsString;
        
        document.body.insertAdjacentHTML('beforeend', overlay);

        this._el = document.querySelector('.overlay_wrapper') as HTMLElement;
        this._el.classList.add('overlay_' + this._contentId);
        this._closeBtn = this._el.querySelectorAll(this._options.closeBtn);

        // this.dispatch({type: Overlay.BEFORE_OPEN});
        if (this._options.autoOpen) this._open();
    }

    /* 
    * Open overlay
    */
    protected _open() {
        this._isOpen = true;
        this._scrollTop = window.pageYOffset; // save scroll value
        document.documentElement.classList.add('hide_overflow'); // prevent document scroll
        TweenMax.set(document.documentElement.querySelector('.wrapper'), {y: -this._scrollTop});

        TweenMax.set(this._el, { autoAlpha: 1 });
        TweenMax.to(this._el, 0.1, { autoAlpha: 1, ease: Expo.easeOut, onStart: () => {
            // Callback when opening animation is complete
            this.dispatch({type: Overlay.BEFORE_OPEN});
        }, onComplete: () => {
            this.dispatch({type: Overlay.OPEN});
            // We set focus on the first element available inside the overlay
            (this._el.querySelectorAll('[tabindex="-1"]')[0] as HTMLElement).focus();
        } });

        // Animate content wrapper inside overlay
        TweenMax.from(this._el.querySelector('.overlay_content'), 0.5, {delay: 0.2, y: -70, alpha: 0, ease: Expo.easeOut});

        // Binding closing behaviors
        for(let i = 0; i < this._closeBtn.length; i++) {
            this._closeBtn[i].addEventListener('click', this._close.bind(this));
        }

        document.addEventListener('keyup', this._onEsc);
        this._el.addEventListener('click', this._onWrapperclick);
    }

    /* 
    * close overlay
    */
    protected _close() {
        this._isOpen = false;
        TweenMax.set(document.documentElement.querySelector('.wrapper'), {clearProps: 'y'});
        document.documentElement.classList.remove('hide_overflow');
        window.scrollTo(0, this._scrollTop);

        this.dispatch({ type: Overlay.BEFORE_CLOSE });

        for(let i = 0; i < this._closeBtn.length; i++) {
            this._closeBtn[i].removeEventListener('click', this._close);
        }

        TweenMax.to(this._el, 0.5, {autoAlpha: 0, ease: Expo.easeOut, onComplete: () => {
            this._el.parentNode.removeChild(this._el);
            document.removeEventListener('keyup', this._onEsc);
            this.dispatch({ type: Overlay.CLOSE });
        }});

        // Return focus to specified element
        if (this._options.closeFocus !== null) {
            this._options.closeFocus.focus();
        }

    }

    public get isOpen(): boolean { return this._isOpen; }
}

/* 
* Options
* 
*/
export class OverlayOptions {
    private _closeBtn           : string        = '[data-js="close"]';
    private _closeFocus         : HTMLElement   = null; 
    private _templateId         : string        = 'youtube';
    private _autoOpen           : boolean       = false;

    public get closeBtn(): string { return this._closeBtn; }
    public set closeBtn(closeBtn: string) { this._closeBtn = closeBtn; }

    public get closeFocus(): HTMLElement { return this._closeFocus; }
    public set closeFocus(clickedItem: HTMLElement) { this._closeFocus = clickedItem; }
    
    public get templateId(): string { return this._templateId; }
    public set templateId(templateId: string) { this._templateId = templateId; }
    
    public get autoOpen(): boolean { return this._autoOpen; }
    public set autoOpen(autoOpen: boolean) { this._autoOpen = autoOpen; }
}