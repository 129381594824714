/**
 * ScrollUtils
 * (c) lg2fabrique 2016
 *
 */


import Context from '../core/Context';
import VanillaUtils from './VanillaUtils';

declare var TweenMax:any;

export default class ScrollUtils{

    public static isScroll              : boolean                   = false;
    public static scrollingContainer;

    public static registredElements     : any[]                     = [];
    public static registredEvents       : any[]                     = [];
    public static isFirst               : boolean                   = true;

    private static _id                  : number                    = 0;


    /**
     * Scroll handler
     * @param {Object} Scroll event;
     */
    private static scroll(e){
        //set scroll position
        e.scrollY = e.el.pageYOffset || document.documentElement.scrollTop;
        e.scrollX = e.el.pageXOffset || document.documentElement.scrollLeft;

        //validate positions of all registred elements
        for(var i in ScrollUtils.registredElements){
            var obj = ScrollUtils.registredElements[i];

            //get top absolute position of element
            var top = 0;
            var el = obj.element;

            if(el){
                if(typeof el.position !== 'number'){
                    do {
                        top += el.offsetTop  || 0;
                        el = el.offsetParent;
                    } while(el);
                    el = obj.element;
                }else{
                    top = el.position;
                }
            }

            //absolute position
            var pos = e.scrollY - top;

            //console.log(pos + obj.offset)
            //element is after

            if (obj.isAfter === null) {
                obj.isAfter = pos + obj.offset >= 0;
            }

            if (pos + obj.offset >= 0 && obj.isAfter === true) {
                obj.isAfter = !obj.isAfter;
                if (obj.after) obj.after(obj.element);
                if (obj.isOnce) ScrollUtils.removeRegistredElement(obj.element);
            } else if (pos + obj.offset < 0 && obj.isAfter === false) {
                obj.isAfter = !obj.isAfter;
                if (obj.before) obj.before(obj.element);
                if (obj.isOnce) ScrollUtils.removeRegistredElement(obj.element);
            }
        }

        ScrollUtils.isFirst = false;
    }

    public static triggerEventOf(el:any){
        if(ScrollUtils.hasEvent(el)){
            var event;
            if(document.createEvent){
                event = document.createEvent('HTMLEvents');
                event.initEvent('scroll',true,true);
            }else if((document as any).createEventObject){
                event = (document as any).createEventObject();
                event.eventType = 'scroll';
            }
            event.eventName = 'scroll';
            if(el.dispatchEvent) el.dispatchEvent(event);
            else if(el.fireEvent ) el.fireEvent('onscroll', event);
            else if(el.scroll) el.scroll(event);
            else if(el.onscroll) el.onscroll(event);
        }else{
            throw new Error('No scroll event is found for: ' + el);
        }
    };


    /**
     * Has scroll event
     * @param {HTMLElement} DOM element;
     */
    private static hasEvent(el) {
        if(ScrollUtils.registredEvents.indexOf(el) !== -1) return true;
        else return false;
    }


    /**
     * Register scroll event to...
     * @param {HTMLElement} DOM element
     * @param {Function} Callback
     * @param {Boolean} Force override (will be replaced by this callback if the event is defined)
     *   ScrollUtils.registerEventTo(window, function(e){
                console.log(e.scrollY);
                console.log(e.scrollX);
            }, true);
     */
    public static registerEventTo (target:any, callback?:Function, force?:boolean){

        if(force === undefined) force = false;

        if(ScrollUtils.hasEvent(target) && force === false){
            throw new Error('The scroll event for this [' + target + '] is defined. Use "force" parameter to replace it.');
        } else if(ScrollUtils.hasEvent(target) && force === true){
            ScrollUtils.removeRegistredEventOf(target);
        }

        //extend fonction
        target.scroll = (function(el, callback) {
            return function(e) {
                e.el = el;
                this.scroll(e);
                if(callback) callback(e);
            };
        })(target, callback).bind(this);


        //listener validation + add event
        if(target.addEventListener) target.addEventListener('scroll', target.scroll, false);
        else if(target.attachEvent) target.attachEvent('onscroll', target.scroll);
        else target.onscroll = target.scroll;

        ScrollUtils.registredEvents.push(target);
    }


    /**
     * Remove registred event of
     * @param {HTMLElement} DOM element
     *   ScrollUtils.removeRegistredEventOf(window);
     */
    public static removeRegistredEventOf = function(el){
        for (var i in ScrollUtils.registredEvents) {
            if (ScrollUtils.registredEvents[i] === el) {
                ScrollUtils.registredEvents.splice(Number(i), 1);

                if(el.removeEventListener) el.removeEventListener('scroll', el.scroll, false);
                else if(el.detachEvent) el.detachEvent('onscroll', el.scroll);
                else el.onscroll = null;
            }
        }
    };


    /**
     * Register element to scroll
     * @param {HTMLElement} DOM element
     * @param {Object} Options
         offset: {Number} Offset to apply
         after: {Function} Element is after scroll top
         before: {Function} Element is below scroll top

        ScrollUtils.registerElement($('.home .menu')[0], {
            after:function(){},
            before:function(){}
        });
     */
    public static registerElement = function(el:any, obj){

        //number element
        if(typeof el === 'number') el = {position: el};

        //registred scroll event if is not defined
        if(!ScrollUtils.hasEvent(window)){
            ScrollUtils.registerEventTo(window);
        }

        ScrollUtils._id++;

        var key = 'element' + ScrollUtils._id;

        //push registed element
        ScrollUtils.registredElements.push({
            id:key,
            isAfter: null,
            isOnce: obj.isOnce === undefined ? false: obj.isOnce,
            element: el,
            offset: obj.offset === undefined ? 0 : obj.offset,
            after: obj.after,
            before: obj.before
        });

        return key;
    };


    /**
     * Register element to scroll (trigger only once)
     * @param {HTMLElement} DOM element
     * @param {Object} Options
         offset: {Number} Offset to apply
         after: {Function} Element is after scroll top
         before: {Function} Element is below scroll top

        ScrollUtils.registerOnceElement($('.home .menu')[0], {
            after:function(){},
            before:function(){}
        });
     */
    public static registerOnceElement(el, obj){
        obj.isOnce = true;
        ScrollUtils.registerElement(el, obj);
    };


    /**
     * Update registred event of
     * @param {HTMLElement} DOM element
     * @param {Object} Options to change
     *   ScrollUtils.updateRegistredElement($('.home .menu')[0], {offset:300});
     */
    public static updateRegistredElement(el, obj){
        var prop = 'element';
        if(typeof el === 'string') prop = 'id';
        else if(typeof el === 'number') prop = 'position';

        for(var i in ScrollUtils.registredElements){
            if(ScrollUtils.registredElements[i][prop] === el || (prop === 'element' && ScrollUtils.registredElements[i][prop].name !== undefined && ScrollUtils.registredElements[i][prop].name === el.name)){
                for(var j in obj){
                    ScrollUtils.registredElements[i][j] = obj[j];
                    if(prop === 'element' && ScrollUtils.registredElements[i][prop].position !== undefined &&  el.position !== undefined){
                        ScrollUtils.registredElements[i][prop].position = el.position;
                    }
                }
            }
        }
    };


    /**
     * Remove registred element
     * @param {HTMLElement} DOM element
     *   ScrollUtils.removeRegistredElement($('.home .menu')[0]);
     */
    public static removeRegistredElement(el) {
        var prop = 'element';
        if(typeof el === 'string') prop = 'id';
        else if(typeof el === 'number') prop = 'position';

        for(var i in ScrollUtils.registredElements){
            if(ScrollUtils.registredElements[i][prop] === el){
                ScrollUtils.registredElements.splice(Number(i), 1); //remove item
            }
        }
    };


    /**
     * Kill actual scroll animation
     *   ScrollUtils.killScrollTo();
     */
    public static killScrollTo(){
        TweenMax.killTweensOf(ScrollUtils.scrollingContainer);
    };


    /**
     * Scroll verticaly the document
     * @param options
         target: {HTMLelement|Number} This value is used to determine a target offset to scroll to
         speed: {Number} Speed in seconds to complete the whole scrolling animation (default: 1)
         offset: {Number} Offset to apply
         easing: {Function} TweenMax/TweenLite easing
         onEnd: {Function} Callback on animation complete
         onStart: {Function} Callback on animation start
         onProgress: {Function} Callback on animation progress

         ScrollUtils.scrollTo({
            target:5000,
            speed:2,
            easing:Back.easeOut,
            onEnd:function(tween){
                console.log('ScrollUtils done')
            },
            onProgress:function(tween){
                console.log('ScrollUtils progress')
            },
            onStart:function(tween){
                console.log('ScrollUtils start')
            }
        });
     */
    public static scrollTo(options:IScrollTo){

        var context:Context = Context.getInstance();
        var tween;
        var value:number = 0;

        var speed:number = (options.speed === undefined) ? 1 :  options.speed;
        var ease = (options.easing === undefined) ? '' :  options.easing;
        var target = options.target;

        ScrollUtils.scrollingContainer = (options.scrollingContainer === undefined) ? (window as any) :  options.scrollingContainer;

        if (typeof target === 'number') value = target;
        else {
            value = VanillaUtils.getOffsetRect(target).top;
        }

        if (options.offset !== undefined) value -= options.offset;


        //extend scroll end function
        var scrollEnd =  (function(onEnd) {
            return function(e) {
                ScrollUtils.isScroll = false;
                if(onEnd) onEnd(tween);
            };
        })(options.onEnd);

        //extend scroll start function
        var scrollStart =  (function(onStart) {
            return function(e) {
                ScrollUtils.isScroll = true;
                if(onStart) onStart(tween);
            };
        })(options.onStart);

        //extend scroll progress function
        var scrollProgress =  (function(onProgress) {
            return function(e) {
                ScrollUtils.isScroll = true;
                if(onProgress) onProgress(tween);
            };
        })(options.onProgress);


        //adjuste maximal position
        var viewport = context.viewport;
        var maxHeight = viewport.htmlHeight - viewport.height;
        if (value > maxHeight) value = maxHeight;


        //start to scroll
        if((window as any).TweenMax) {
            tween = TweenMax.to(ScrollUtils.scrollingContainer, speed, {
                scrollTo: {y:value, x:0},
                ease: ease,
                autoKill: false,
                onComplete: scrollEnd,
                onStart: scrollStart,
                onUpdate: scrollProgress
            });
        } else throw new Error('TweenMax must be added in the projet');
    };

}

//URI interface
interface IScrollTo{
    target: any;
    scrollingContainer?: any;
    speed?: number;
    easing?: any,
    offset?: number;
    onStart?: Function;
    onEnd?: Function;
    onProgress?: Function;
}