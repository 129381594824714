/*
* Object fit 
* Use background-image: cover
*/

export default class ObjectFitPolyfill {
    private _els : NodeListOf<HTMLElement>;

    constructor() {
        this._els = document.querySelectorAll('[data-object-fit]') as NodeListOf<HTMLElement>;    
        
        // css object-fit polyfill
        if ('objectFit' in document.documentElement.style === false) {
            this._init();
        }
    }

    private _init() {
        console.info('object-fit not natively supported');

        for (let i = 0, l = this._els.length; i < l; i++) {            
            let $container = this._els[i] as HTMLElement
            if ($container.querySelector('img')) { // replace images
                var src = $container.querySelector('img').getAttribute('src');
            }
            if (src) {
                $container.style.backgroundImage = 'url(' + src + ')';
                $container.classList.add('no-object-fit');
            }
        }
    }
}

/*
    * HTML
    <div class="object-fit-wrp" data-object-fit="cover">
        <img class="object-fit" src="{{ slide.slideImage.first().getUrl() }}" alt="">
    </div>
    
    * CSS
    .object-fit {
        object-fit: cover;
        width: 100%;
        height: 100%;
        @media #{$mqTabletP} {
            object-fit: fill;
        }
    }
    .object-fit-wrp {
        height: 100%;
        width: 100%;
    }
    .no-object-fit {
        background-position: 50% 50%;
        background-size: cover;
        object-fit: fill;
        .object-fit { opacity: 0; }
    }  
*/