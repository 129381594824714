// import { UIElements } from '../modules/UIElements';
import Sliders from '../modules/Sliders';

declare var dataLayer: any; 

export class Jobs {
    private _scrollToBtn              : HTMLElement;
    private _slider             : Sliders;
    private _sliderObj          : any;
    private _firstInteraction   : boolean           = true;
    
    constructor() {
        //const category = document.querySelector('[data-js="category"]').innerHTML;
        //const projectName = document.querySelector('h1.page_title').innerHTML;
        
        this._sliderObj = {
            autoHeight: true,
            autoplay: 3000,
            autoplayDisableOnInteraction: false,    
            pagination: '.swiper-pagination',
            paginationClickable: true,
            parallax: true, 
            speed: 1000,
            slidesPerView: 1,
            loop: false,
            /*onClick: () => {    
                if(this._firstInteraction) {
                    dataLayer.push({ eventCategory: 'page projet', eventAction: 'carrousel', eventLabel: category + '-' + projectName, event: 'genericGAEvent' });
                    this._firstInteraction = false;
                }
            }*/
        }; 

        this._slider = new Sliders('.swiper-container', this._sliderObj);

        //dataLayer.push({ typeProjet: category });
    }  
}