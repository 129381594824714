import Breakpoint from 'frontools/core/Breakpoint';
export class Contact {
    private _mapElements            : HTMLElement;
    constructor() {
        this._mapElements = document.querySelector('.map_elements') as HTMLElement;
        if (Breakpoint.isHigherThan('tabletp')) {
            this._animateLine();
        }
    }

    private _animateLine() {
        const line = this._mapElements.querySelector('.map_line');
        const dot = this._mapElements.querySelector('.map_dot');
        const logo = this._mapElements.querySelector('.map_logo');

        let tl = new TimelineMax();
        tl.from(this._mapElements, 1, { y: '-100%', ease: Expo.easeOut}, '+=0.8')
        .from([dot, logo], 0.3, { autoAlpha: 0});
    }
}