import Overlay from './Overlay';
import { OverlayOptions } from './Overlay';
import Youtube from 'frontools/medias/video/Youtube';
import { YoutubeObject } from 'frontools/medias/video/Youtube';
declare var dataLayer: any

export default class OverlayYouTube extends Overlay {
    protected _contentId        : string;
    private _youtubeId          : string;
    private _ytOptions          : YoutubeObject;
    private _video              : Youtube;
    private _isPlaying          : boolean;
    private _pageId             : string;
    
    constructor(id) {
        let _options = new OverlayOptions(); // Get options and override if needed
        super(_options);

        this._pageId = document.querySelector('.p_home') ? 'accueil' : 'à propos'; // ugly fast fix for gtm, should get page name 
        
        this._contentId = _options.templateId; // get youtube template
        this._youtubeId = id; // get video id
        this._open();
    }

    protected _close() {
        super._close();
        if (this._video.player) {
            this._video.player.stopVideo();
        }
        this._video = null;
        this._isPlaying = false;
    }
    
    protected _generateTemplate() {
        super._generateTemplate(this._contentId);
    }
     
    protected _open() {
        this._initYt(this._youtubeId); // init youtube before open
        super._open();
    }
    
    private _initYt(id) {
        this._ytOptions = new YoutubeObject();
        this._ytOptions.id = id;
        this._ytOptions.target = document.querySelector('.video_wrp') as HTMLElement;
        this._ytOptions.playerSettings.controls = 2;
        this._ytOptions.playerSettings.autoplay = 1;

        this._video = new Youtube(this._ytOptions);

        this._video.addListener(Youtube.STATE_CHANGE, this._onVideoState.bind(this));
        this._video.addListener(Youtube.PROGRESS_TRACK, this._progressTrack.bind(this));
    }

    private _onVideoState(e) {
        if (e.data === 1 && !this._isPlaying) {
            this._pushDatalayer('play');
            this._isPlaying = true;
        } else if (e.data === 0) { // video ended
            this._pushDatalayer('100%');
        }
    }

    private _progressTrack(e) {
        if(e.second === 30) {
            this._pushDatalayer('30sec');
        } else {
            this._pushDatalayer(e.percent + '%');
        }
    }

    private _pushDatalayer(label: string) {
        dataLayer.push({ eventCategory: this._pageId, eventAction: 'vidéo histoire', eventLabel: label, event: 'genericGAEvent' });
    }
}