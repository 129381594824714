/*
* Object fit 
* Resize image
*/

export default class ObjectFit {
    private _els : NodeListOf<HTMLElement>;

    constructor() {
        this._els = document.querySelectorAll('[data-object-fit]') as NodeListOf<HTMLElement>;
        
        // css object-fit polyfill
        if ('objectFit' in document.documentElement.style === false) {
            this._init();
        }
    }

    private _init() {
        console.info('object-fit not natively supported');
        this._resize();
        window.addEventListener('resize', this._resize.bind(this), false);
    }

    private _resize() {
        for (let i = 0, l = this._els.length; i < l; i++) {            
            this._els[i].classList.add('no_object_fit');          
            // get the parent element size
            // let vid = this._els[i].querySelector('video') as HTMLElement;
            let poster = this._els[i].querySelector('img') as HTMLElement;
            var container_w = this._els[i].offsetWidth;
            var container_h = this._els[i].offsetHeight

            // use largest scale factor of horizontal/vertical
            var scale_w = container_w / 1280;
            var scale_h = container_h / 720;
            var scale = (scale_w > scale_h) ? scale_w : scale_h;

            // scale the video
            // if (vid) {
            //     vid.style.width = (scale * 1280)+'px';
            //     vid.style.height = (scale * 720)+'px';
            // }
            if (poster) {
                poster.style.width = (scale * 1280)+'px';
                poster.style.height = (scale * 720)+'px';
            }
        }
    }
}