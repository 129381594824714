import AbstractMain from 'frontools/abstract/AbstractMain';
import Breakpoint from 'frontools/core/Breakpoint';
import { ProjectsList } from './pages/ProjectsList';
import { Home } from './pages/Home';
import { Header } from './shared/Header';
import { ProjectDetail } from './pages/ProjectDetail';
import { Jobs } from './pages/Jobs';
import Accessibility from 'frontools/utils/Accessibility';
import { UIElements } from './modules/UIElements';
import LazyLoadManager from 'frontools/medias/image/LazyLoadManager';
import LazyLoadManagerOptions from 'frontools/medias/image/LazyLoadManager';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { SmoothScroll } from './shared/SmoothScroll';

export class Main extends AbstractMain {
    public breakpoint               : Breakpoint;

    private _body                   : HTMLElement;
    private _scrollDown             : HTMLElement;

    private _header                 : Header;
    private _UIElements             : UIElements;

    constructor(context:Object = {}) {
        super(context);
        
        this._body = document.body;
        this._scrollDown = document.querySelector('.scroll_down');

        this._header = new Header(); 

        if (this._scrollDown) new SmoothScroll(this._scrollDown);
        
        if (this._body.classList.contains('p_home')) new Home();
        else if (this._body.classList.contains('p_projectsList') || this._body.classList.contains('p_category')) new ProjectsList();
        else if (this._body.classList.contains('p_projects')) new ProjectDetail();
        else if (this._body.classList.contains('p_about')) new About();
        else if (this._body.classList.contains('p_contactUs')) new Contact();
        else if (this._body.classList.contains('p_jobs')) new Jobs();
        

        this._UIElements = new UIElements();
        
        Breakpoint.trigger();    
    }

    /*
    * Override breakpoints init to add new groups
    */
    protected initBreakpoint() {
        this.breakpoint = new Breakpoint({
            debug: true,
            breakpoints: [
                Breakpoint.MOBILES = 'mobiles',
                Breakpoint.MOBILE = 'mobile',
                Breakpoint.TABLETP = 'tabletp',
                Breakpoint.TABLET = 'tablet',
                Breakpoint.DESKTOP = 'desktop',
                Breakpoint.LARGE = 'large',
                Breakpoint.HD = 'hd'
            ],
            groups: {
                'group_mobile': [
                    Breakpoint.MOBILES,
                    Breakpoint.MOBILE,
                    Breakpoint.TABLETP
                ],
                'group_desktop': [
                    Breakpoint.TABLET,
                    Breakpoint.DESKTOP,
                    Breakpoint.LARGE,
                    Breakpoint.HD
                ],
                'group_small': [
                    Breakpoint.MOBILES,
                    Breakpoint.MOBILE,
                    Breakpoint.TABLETP,
                    Breakpoint.TABLET
                ],
                'group_large': [
                    Breakpoint.DESKTOP,
                    Breakpoint.LARGE,
                    Breakpoint.HD
                ],
            }
        });
    }
}